import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import logo from "../../assets/logo.png";
import Connect from "./Connect";
import Button from "@mui/material/Button";
import TopLogo from "./TopLogo";

const Wrapper = styled("div")(({ theme }) => ({
  maxWidth:480,
  margin: "0 auto",
  textAlign: "center",
  paddingBottom: 24,
  [theme.breakpoints.down("md")]: {
    h5: {
      fontSize: 20,
      margin: 0,
    },
  },
}));

export default function Header() {
  return (
    <Wrapper>
        <TopLogo responsive={true} />
        <TopLogo responsive={false} />
        <video width={520} height={368}  poster={`/static/videos/extract.jpg`} autoPlay loop muted
               playsInline>
            <source type="video/mp4" src={`/static/videos/extract.mp4`}/>
        </video>
      <Connect responsive={false} />
      <Typography variant="h6" marginTop={-3}>
        <strong>BlackHole Energy Extraction</strong>
      </Typography>
    </Wrapper>
  );
}
