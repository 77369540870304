import {useAuthContext} from "../../providers/AuthProvider";
import {styled} from "@mui/system";
import logo from "../../assets/logo.png";
import Button from "@mui/material/Button";

const TopLogoContent = styled("div")(({ theme }) => ({
    position: "fixed",
    left: 48,
    top: 24,
    [theme.breakpoints.down("md")]: {
        display: "none",
    },
}));

const TopLogoContent2 = styled(Button)(({ theme }) => ({
    display: "none",
    marginTop: 20,
    marginBottom: 0,
    width: "95%",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("md")]: {
        display: "block",
    },
}));

export default function TopLogo({ responsive = true }) {
    return responsive ? (
        <TopLogoContent src={logo} alt="" >
            <a href={`https://galaxywar.io`} target={`_blank`}><img src={logo} width={"360"} /></a>
        </TopLogoContent>
    ) : (
        <TopLogoContent2>
            <a href={`https://galaxywar.io`} target={`_blank`}><img src={logo} width={"360"} /></a>
        </TopLogoContent2>
    );
}