import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Connect from "./components/Connect";
import Header from "./components/Header";
import BakeCard from "./components/BakeCard";
import NutritionFacts from "./components/NutritionFacts";
import ReferralLink from "./components/ReferralLink";
import { useAuthContext } from "../providers/AuthProvider";
import Footer from "./components/Footer";
import FAQs from "./components/FAQ";
// import TwitterFeed from "./components/TwitterFeed";

const Wrapper = styled("div")(({ theme }) => ({
  maxWidth:960,
  margin: "0 auto",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
  },
}));

export default function Home() {
  const { address } = useAuthContext();

  return (
    <Wrapper>
      <Connect />
      <Header />
        <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
                <BakeCard />
            </Grid>
            <Grid item xs={12} sm={6}>
                <NutritionFacts />
                <ReferralLink address={address} />
                <FAQs />
            </Grid>
        </Grid>


      <Footer />
    </Wrapper>
  );
}