export const config = {
  LOCALHOST: {
    chainId: 31337,
    contractAddress: "0x0DCd1Bf9A1b36cE34237eEaFef220932846BCD82",
    scanLink:
        "http://127.0.0.1/explorer/address/0x5FbDB2315678afecb367f032d93F642f64180aa3\n",
  },
  BSC_TESTNET: {
    chainId: 97,
    contractAddress: "0x6C3C02c57361ed74abcC0AD24B2908e9Dc88593A",
    scanLink:
        "https://bscscan.com/address/0x6C3C02c57361ed74abcC0AD24B2908e9Dc88593A",
  },
  BSC: {
    chainId: 56,
    contractAddress: "0x59299878F610610d4F13A48BFa42592Dd675f93F",
    scanLink:
      "https://bscscan.com/address/0x59299878F610610d4F13A48BFa42592Dd675f93F",
  },
  ROPSTEN: {
    chainId: 3,
    contractAddress: "0x59299878F610610d4F13A48BFa42592Dd675f93F",
    scanLink:
      "https://ropsten.etherscan.io/address/0x59299878F610610d4F13A48BFa42592Dd675f93F",
  },
  AVAX: {
    chainId: 43114,
    contractAddress: "0x59299878F610610d4F13A48BFa42592Dd675f93F",
    scanLink:"https://snowtrace.io/address/0x59299878F610610d4F13A48BFa42592Dd675f93F",
  },
  POLYGON: {
    chainId: 137,
    contractAddress: "0x59299878F610610d4F13A48BFa42592Dd675f93F",
    scanLink:"https://polygonscan.com/address/0x59299878F610610d4F13A48BFa42592Dd675f93F",
  }

};
