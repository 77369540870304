import Button from "@mui/material/Button";
import { styled } from "@mui/system";
import Warning from "@mui/icons-material/Warning";
import Alert from '@mui/material/Alert';
import { useAuthContext } from "../../providers/AuthProvider";
import * as React from "react";
import {useContractContext} from "../../providers/ContractProvider";
import QuestionAnswer from "@mui/icons-material/QuestionAnswer";

const ConnectButton = styled(Button)(({ theme }) => ({
  position: "fixed",
  right: 48,
  top: 48,
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const WrongNetButton = styled(Button)(({ theme }) => ({
  position: "fixed",
  right: 208,
  top: 48,
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const SmallScreenConnectButton = styled(Button)(({ theme }) => ({
  display: "none",
  marginTop: -24,
  marginBottom: 48,
  width: "95%",
  marginLeft: "auto",
  marginRight: "auto",
  [theme.breakpoints.down("md")]: {
    display: "block",
  },
}));

export default function Connect({ responsive = true }) {
  const { address, loading, connect, disconnect, chainId } = useAuthContext();
  const { contract, wrongNetwork, getBnbBalance, fromWei, toWei, web3 } =
      useContractContext();

  return responsive ? (
      <>
        <ConnectButton
            color="flare"
            variant="contained"
            disabled={loading}
            onClick={() => (address ? disconnect() : connect())}
            startIcon={ wrongNetwork && <Warning />}
        >
          <strong> {address ? "Disconnect" : "Connect"}</strong>
        </ConnectButton>
        {/*{wrongNetwork && <Alert variant="filled" severity="error">*/}
        {/*  Wrong network!*/}
        {/*</Alert>}*/}

      </>
  ) : (
    <SmallScreenConnectButton
      color="flare"
      variant="contained"
      disabled={loading}
      onClick={() => (address ? disconnect() : connect())}
    >
      <strong> {address ? "Disconnect" : "Connect"}</strong>
    </SmallScreenConnectButton>
  );
}
